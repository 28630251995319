import PieChart from '@mui/icons-material/PieChart'
import { generatePath } from 'react-router-dom'

import {
  CatalogIcon,
  DashboardIcon,
  FulfillmentIcon,
  PurchasesIcon,
  ReportIcon,
  SettingsIcon,
  StockControlIcon,
  WarehouseIcon,
  CrossDockIcon,
  SalesIcon
} from '~/components/Icons/nav'
import * as ROUTES from '~/constants/routes'
import { OVERVIEW_TABS } from '~/constants/tabs'

import * as TITLES from './nav-titles'

export default [
  {
    items: [
      {
        title: TITLES.DASHBOARD,
        href: '/dashboard',
        icon: DashboardIcon,
        enabled: true,
        items: [
          {
            title: TITLES.OVERVIEW,
            href: generatePath(ROUTES.OVERVIEW_PATH, { tab: OVERVIEW_TABS.FULFILLMENT }),
            name: TITLES.OVERVIEW,
            perm: 'dashboard.overview.overview-view',
            enabled: true
          },
          {
            title: 'Fulfillment',
            href: '/dashboard/fulfillment'
          },
        ]
      },
      {
        title: TITLES.REPORTS,
        icon: ReportIcon,
        href: ROUTES.REPORT_LIST_PATH,
        name: TITLES.REPORTS,
        perm: 'dashboard.overview.overview-view',
        enabled: true
      },
      {
        title: TITLES.CATALOG,
        href: '/catalog',
        icon: CatalogIcon,
        enabled: true,
        items: [
          {
            title: TITLES.PRODUCTS,
            href: ROUTES.PRODUCT_LIST_PATH,
            name: TITLES.PRODUCTS,
            perm: 'catalog.products.product-view',
            enabled: true
          },
          {
            title: TITLES.VARIANTS,
            href: ROUTES.VARIANT_LIST_PATH,
            name: TITLES.VARIANTS,
            perm: 'catalog.variants.variant-view',
            enabled: true
          },
          {
            title: TITLES.UNITS,
            href: ROUTES.UNIT_LIST_PATH,
            name: TITLES.UNITS,
            perm: 'catalog.units.unit-view',
            enabled: true
          },
          {
            title: TITLES.BUNDLES,
            href: ROUTES.BUNDLE_LIST_PATH,
            name: TITLES.BUNDLES,
            perm: 'catalog.products.product-view',
            enabled: true
          },
          {
            title: TITLES.CATEGORIES,
            href: ROUTES.CATEGORY_LIST_PATH,
            name: TITLES.CATEGORIES,
            perm: 'catalog.categories.category-view',
            enabled: true
          },
          {
            title: TITLES.BRANDS,
            href: ROUTES.BRAND_LIST_PATH,
            name: TITLES.BRANDS,
            perm: 'catalog.brands.brand-view',
            enabled: true
          }
        ]
      },
      {
        title: TITLES.SALES,
        href: '/sales',
        icon: SalesIcon,
        enabled: true,
        items: [
          {
            title: TITLES.SALE_ORDERS,
            href: ROUTES.SALE_ORDER_LIST_URL,
            name: TITLES.SALE_ORDERS,
            perm: 'fulfillment.orders.order-view',
            enabled: true
          },
          {
            title: 'Customers',
            href: ROUTES.CUSTOMER_LIST_URL,
            name: TITLES.CUSTOMERS,
            perm: 'fulfillment.customers.customer-view',
            enabled: true
          },
          {
            title: TITLES.INVOICES,
            href: ROUTES.INVOICE_LIST_PATH,
            name: TITLES.INVOICES,
            perm: 'fulfillment.orders.order-view',
            enabled: true
          },
          {
            title: TITLES.SALE_RETURNS,
            href: ROUTES.RETURN_LIST_PATH,
            name: TITLES.SALE_RETURNS,
            perm: 'fulfillment.shipments.shipment-view',
            enabled: true
          },
        ]
      },
      {
        title: TITLES.PURCHASES,
        href: '/purchases',
        icon: PurchasesIcon,
        enabled: true,
        items: [
          {
            title: TITLES.PURCHASE_ORDER,
            href: ROUTES.PURCHASE_ORDER_LIST_URL,
            name: TITLES.PURCHASE_ORDER,
            perm: 'purchases.purchase-orders.purchase-order-view',
            enabled: true
          },
          {
            title: TITLES.SUPPLIERS,
            href: ROUTES.SUPPLIER_LIST_URL,
            name: TITLES.SUPPLIERS,
            perm: 'purchases.suppliers.supplier-view',
            enabled: true
          },
          {
            title: TITLES.SUPPLIER_RETURNS,
            href: ROUTES.SUPPLIER_RETURN_LIST_PATH,
            name: TITLES.SUPPLIER_RETURNS,
            perm: 'purchases.purchase-orders.purchase-order-view',
            enabled: true
          },
        ]
      },
      {
        title: TITLES.FULFILLMENT,
        href: '/fulfillment',
        icon: FulfillmentIcon,
        enabled: true,
        items: [
          {
            title: TITLES.PICKLISTS,
            href: ROUTES.PICKLIST_LIST_URL,
            name: TITLES.PICKLISTS,
            perm: 'fulfillment.picklists.picklist-view',
            enabled: true
          },
          {
            title: TITLES.PACKING,
            href: ROUTES.PACKING_LIST_PATH,
            name: TITLES.PACKING,
            perm: 'fulfillment.packing.packing-view',
            enabled: true
          },
          {
            title: TITLES.SHIPMENTS,
            href: ROUTES.SHIPMENT_LIST_PATH,
            name: TITLES.SHIPMENTS,
            perm: 'fulfillment.shipments.shipment-view',
            enabled: true
          },
        ]
      },
      {
        title: TITLES.STOCK,
        href: '/stock',
        icon: StockControlIcon,
        enabled: true,
        items: [
          {
            title: TITLES.RECEIVE,
            href: ROUTES.RECEIVE_LIST_PATH,
            name: TITLES.RECEIVE,
            perm: 'stock.receive.receipt-view',
            enabled: true
          },
          {
            title: TITLES.V2_PUTAWAY,
            href: ROUTES.V2_PUTAWAY_LIST_PATH,
            name: TITLES.V2_PUTAWAY,
            perm: 'stock.putaway.putaway-job-view',
            enabled: true
          },
          {
            title: TITLES.ADJUSTMENT,
            href: ROUTES.ADJUSTMENT_LIST_PATH,
            name: TITLES.ADJUSTMENT,
            perm: 'stock.adjustments.adjustment-view',
            enabled: true
          },
          {
            title: TITLES.STOCK_COUNT,
            href: ROUTES.STOCK_COUNT_LIST_PATH,
            name: TITLES.STOCK_COUNT,
            perm: 'stock.stock-count.stock-count-view',
            enabled: true
          },
          {
            title: TITLES.MOVEMENT,
            href: ROUTES.MOVEMENT_LIST_PATH,
            name: TITLES.MOVEMENT,
            perm: 'stock.movements.movement-view',
            enabled: true
          },
          {
            title: TITLES.TRANSFER,
            href: ROUTES.TRANSFER_LIST_PATH,
            name: TITLES.TRANSFER,
            perm: 'stock.stock-transfers.stock-transfer-view',
            enabled: true
          },
          {
            title: TITLES.REORDER,
            href: ROUTES.REORDER_LIST_PATH,
            name: TITLES.REORDER,
            perm: 'stock.adjustments.adjustment-view',
            enabled: true
          }
        ]
      },
      {
        title: TITLES.CROSS_DOCKS,
        href: '/cross-docks',
        icon: CrossDockIcon,
        enabled: true,
        beta: true,
        betaRightPx: '32px',
        items: [
          {
            title: TITLES.BUCKETS,
            href: ROUTES.BUCKETS_LIST_PATH,
            name: TITLES.BUCKETS,
            perm: 'cross_docking.buckets.bucket-view',
            enabled: true
          },
          {
            title: TITLES.LOT_SHEETS,
            href: ROUTES.LOT_SHEETS_LIST_PATH,
            name: TITLES.LOT_SHEETS,
            perm: 'cross_docking.lots.lot-view',
            enabled: true
          },
          {
            title: TITLES.CROSS_SHIPMENTS,
            href: ROUTES.CROSS_SHIPMENT_LIST_PATH,
            name: TITLES.CROSS_SHIPMENTS,
            perm: 'cross_docking.lot-shipments.lot-shipment-view',
            enabled: true
          },
        ]
      },
      {
        title: TITLES.WAREHOUSES,
        icon: WarehouseIcon,
        href: ROUTES.WAREHOUSE_LIST_PATH,
        name: TITLES.WAREHOUSE,
        perm: 'warehouse.warehouses.warehouse-view',
        enabled: true
      },
      {
        title: 'Reports',
        href: '/reports',
        icon: PieChart,
        enabled: true,
        items: [
          {
            title: 'Sale',
            href: '/reports/sale',
          },
          {
            title: 'Product',
            href: '/reports/product',
          },
          {
            title: 'Inventory',
            href: '/reports/inventory',
          },
          {
            title: 'Carrier Shipments',
            href: '/reports/carrier-shipments',
          },
          {
            title: 'Stock take',
            href: '/reports/stock-take',
          },
        ]
      },
      {
        title: TITLES.SETTINGS,
        href: '/settings',
        icon: SettingsIcon,
        enabled: true,
        items: [
          {
            title: TITLES.ACCOUNT,
            href: ROUTES.ACCOUNT_UPDATE_PATH,
            name: TITLES.ACCOUNT,
            perm: 'settings.accounts.account-view',
            enabled: true
          },
          {
            title: TITLES.INTEGRATION,
            href: ROUTES.INTEGRATION_LIST_PATH,
            name: TITLES.INTEGRATION,
            perm: 'settings.integrations.integration-view',
            enabled: true
          },
          {
            title: TITLES.CONFIGURATION,
            href: ROUTES.CONFIGURATION_MENU_PATH,
            name: TITLES.CONFIGURATION,
            perm: 'settings.accounts.account-view',
            enabled: true
          },
          {
            title: TITLES.USERS,
            href: ROUTES.USER_LIST_PATH,
            name: TITLES.USERS,
            perm: 'settings.users.user-view',
            enabled: true
          },
          {
            title: TITLES.ROLES,
            href: ROUTES.ROLE_LIST_PATH,
            name: TITLES.ROLES,
            perm: 'settings.roles.role-view',
            enabled: true
          },
          {
            title: TITLES.BARCODE,
            href: ROUTES.BARCODE_GENERATOR_PATH,
            name: TITLES.BARCODE,
            perm: 'settings.roles.role-view',
            enabled: true
          },
          {
            title: TITLES.AGGRAK,
            href: ROUTES.AGGRAK_PATH,
            name: TITLES.AGGRAK,
            perm: 'settings.integrations.integration-view',
            enabled: true
          }
        ]
      },
    ]
  }
]
